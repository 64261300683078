import React, { FC, useEffect, useState } from "react";
import { useAuth0 } from "../react-auth0-wrapper";
import Loading from "../components/Loading";
import Axios from "axios";

const Authenticated: FC = () => {
  const { token, isLoading, user } = useAuth0();
  const [loggingIn, setLoggingIn] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    // Create a session in SERF using the token and email
    const createSession = async () => {
      try {
        setLoggingIn(true);
        // Make a POST request to the /api/registroSession endpoint to create a new session
        const response = await Axios.post<{ code: number; message: string }>(
          "/generate",
          {
            email: user.email,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // Check that the response code is 0, which indicates a successful response
        if (response.data.code === 0) {
          // Redirect to external url
          window.location.href =
            global.getenv("REACT_APP_SERF_URL") +
            "/fianzas.pkg_admin_accesoserf.sp_captura_session?p_token=" +
            token;
        }
      } catch (error) {
        // If an error occurs, set the errorMessage state to the error message to show it to the user
        setErrorMessage(`${error}`);
      } finally {
        setLoggingIn(false);
      }
    };

    createSession();
  }, [token, user.email]);

  if (isLoading || loggingIn) return <Loading />;
  if (errorMessage) return <div>{errorMessage}</div>;
  return null;
};

export default Authenticated;
