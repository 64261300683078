import React, { FC } from "react";
import "../assets/styles/index.scss";
import { useAuth0 } from "../react-auth0-wrapper";
import { Redirect } from "react-router-dom";

const Index: FC = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect();
  };

  if (isAuthenticated) return <Redirect to="/authenticated" />;
  return (
    <div className="index-page">
      <div className="index-page__container">
        <h1 className="index-page__title">LIBERTY FIANZAS</h1>
        <div className="index-page__content">
          <p>
            Bienvenido/a al Sistema de Emisión Remota de Fianzas. <br />
            Por favor, pulse el botón de abajo para iniciar sesión.
          </p>
          <button
            onClick={handleLogin}
            type="button"
            className="index-page__button"
          >
            Iniciar sesión
          </button>
        </div>
      </div>
    </div>
  );
};

export default Index;
