// src/index.js

import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Auth0ProviderConfigurer from "./auth0-provider-configurer";
import Loading from "./components/Loading";

const Application = () => {
  const [loading, setLoading] = useState(true);

  const loadConfig = async () => {
    return await fetch("/config.json")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const loadEnv = async () => {
      const props = await loadConfig();
      window.env = { ...window.env, ...props };
      setLoading(false);
    };
    loadEnv();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Auth0ProviderConfigurer>
      <App />
    </Auth0ProviderConfigurer>
  );
};

ReactDOM.render(<Application />, document.getElementById("root"));

serviceWorker.unregister();
