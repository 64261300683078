import React from "react";
import { Auth0Provider } from "./react-auth0-wrapper";

import './constants'
import './environment'

const Auth0ProviderConfigurer = ({ children }) => {
  const domain = global.getenv('REACT_APP_AUTH0_DOMAIN');
  const clientId = global.getenv('REACT_APP_AUTH0_CLIENT_ID');
  const audience = global.getenv('REACT_APP_AUTH0_AUDIENCE');

  // A function that routes the user to the right place
  // after login
  const onRedirectCallback = appState => {

    window.history.replaceState(
      {},
      document.title,
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  };

  return (
    <Auth0Provider
      domain={domain}
      client_id={clientId}
      audience={audience}
      intent='default'
      redirect_uri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>

  );
};

export default Auth0ProviderConfigurer;