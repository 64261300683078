import React, { FC } from "react";
import "../assets/styles/loading.scss";

const Loading: FC = () => {
  return <div className="loader-container">
    <div className="loader" aria-label="Cargando..."></div>
  </div>;
};

export default Loading;
