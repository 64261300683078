// src/App.js

import React, { useState, useEffect } from "react";
import { useAuth0 } from "./react-auth0-wrapper";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "@lmig/liberty-ui-react/base-offline.css";
import { useIdleTimer } from "react-idle-timer";
import "./App.css";
import Index from "./pages/Index";
import Loading from "./components/Loading";
import PrivateRoute from "./components/PrivateRoute";
import Authenticated from "./pages/Authenticated";
import Axios from "axios";
import Logout from "./pages/Logout";

function App() {
  const { isLoading, isAuthenticated, getTokenSilently } = useAuth0();
  const [isIdle, setIsIdle] = useState(false);

  const idleTimeout = global.getenv("REACT_APP_USER_IDLE_TIMEOUT") || 5; // in minutes
  const eventsDelay = global.getenv("REACT_APP_ACTIVITY_EVENTS_DELAY") || 30; // in seconds

  const initAxios = () => {
    Axios.defaults.baseURL = global.getenv("REACT_APP_API_URL");
    Axios.defaults.headers.common["Content-Type"] = "application/json";
  };

  const checkSession = async () => {
    if (isAuthenticated && !isIdle) {
      await getTokenSilently();
    }
  };

  const handleOnActive = () => {
    setIsIdle(false);
  };

  const handleOnIdle = () => {
    setIsIdle(true);
  };

  const handleOnAction = () => {
    checkSession();
  };

  useIdleTimer({
    timeout: 1000 * 60 * Number(idleTimeout),
    onActive: handleOnActive,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 500,
    eventsThrottle: Number(eventsDelay) * 1000,
  });

  useEffect(() => {
    checkSession();
  }, [isIdle]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    initAxios();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Index} />
        <PrivateRoute path="/authenticated" component={Authenticated} />
        <PrivateRoute path="/logout" component={Logout} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
