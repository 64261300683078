import React, { FC, useEffect } from "react";
import { useAuth0 } from "../react-auth0-wrapper";

const Logout: FC = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    logout();
  }, [logout]);

  return <div>Cerrando sesión...</div>;
};

export default Logout;
